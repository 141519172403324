<template>
	<el-sub-menu
		:index="route.path"
		:popper-offset="0"
		:show-timeout="200"
		:hide-timeout="200"
		popper-class="menu-popper"
		:class="{ isActive: route.path === activeRoute.path }"
	>
		<template #title>
			<img class="icon default" :src="iconPath" />
			<img class="icon select" :src="selectedIconPath" />
			<span>{{ route.meta && route.meta.title }}</span>
		</template>
		<el-menu-item class="active-title" v-if="isClose" :index="route.path">{{ route.meta && route.meta.title }}</el-menu-item>
		<template v-for="menuItem in route.children" :key="menuItem.name">
			<el-menu-item :index="menuItem.path" class="sub-title" :class="{ isCloseMenu: isClose }">{{ menuItem.meta && menuItem.meta.title }}</el-menu-item>
		</template>
	</el-sub-menu>
</template>
<script setup lang="ts">
import { defineProps, PropType } from 'vue'
import { RouteRecordRaw } from 'vue-router'
import { useStore } from 'vuex'
import { VuexStateKey } from '@/store/vuexEnum'

const props = defineProps({
	route: {
		type: Object as PropType<RouteRecordRaw>,
		required: true,
	},
	activeRoute: {
		type: Object as PropType<RouteRecordRaw>,
		required: true,
	},
})
const store = useStore()
// 导航是否折叠
const isClose = $computed(() => !store.state[VuexStateKey.HAMBURGER_OPENED])
const icons = import.meta.globEager('../img/*') as unknown as Record<
	string,
	{
		default: string
	}
>
const iconPath = $computed(
	() => icons[`../img/menu-${String(props.route.name)}_${props.activeRoute.name === props.route.name ? 'active' : 'default'}.png`].default
)
const selectedIconPath = $computed(() => icons[`../img/menu-${String(props.route.name)}_select.png`].default)
</script>

<style scoped lang="scss">
.icon {
	width: 16px;
	margin-right: 10px;
}
.icon.select {
	display: none;
}
.el-sub-menu {
	.el-menu-item {
		padding-left: 46px !important;
	}
	&.isActive {
		:deep .el-sub-menu__title {
			font-family: $theme-text-family-2 !important;
			font-weight: 500 !important;
			color: $base-color-default !important;
			.el-sub-menu__icon-arrow {
				color: $base-color-default;
			}
			&:hover {
				color: $base-color-default !important;
			}
		}
	}
	&.is-opened {
		:deep .el-sub-menu__title.el-tooltip__trigger {
			background-color: $base-color-default !important;
			.icon.default {
				display: none;
			}
			.icon.select {
				display: inline-block;
			}
		}
	}
}
:deep .el-sub-menu__title {
	height: 44px;
	font-size: 15px;
	font-family: $theme-text-family-1;
	font-weight: 400;
	color: $base-color-gray;
	.el-sub-menu__icon-arrow {
		color: #9999a0;
	}
}
/* :deep .el-sub-menu__title.el-tooltip__trigger {
	&:hover {
		background-color: $base-color-default !important;
		.icon.default {
			display: none;
		}
		.icon.select {
			display: inline-block;
		}
	}
} */
.el-menu-item {
	height: 36px !important;
	font-size: 13px;
	font-family: $theme-text-family-1;
	&:hover {
		background-color: #fff;
	}
	&:not(.is-active, .active-title) {
		&:hover {
			font-family: $theme-text-family-1;
			font-weight: 400;
			color: $base-color-default;
		}
	}
	&.is-active {
		font-family: $theme-text-family-2;
		font-weight: 500;
		color: $base-color-default;
	}
	&.isCloseMenu:not(.is-active) {
		&:hover {
			font-family: $theme-text-family-1;
			font-weight: 400;
			color: $base-color-default;
		}
	}
	&.active-title {
		position: relative;
		height: 44px !important;
		background-color: $base-color-default;
		font-size: 15px;
		font-family: $theme-text-family-2 !important;
		font-weight: 500 !important;
		color: #ffffff;
	}
}
</style>
