import store from '@/store'
import { VuexStateKey, VuexMutationKey } from '@/store/vuexEnum'

export function changeFooterShow(isShow: boolean) {
	store.commit(VuexMutationKey.changeFooterShow, isShow)
}

export function getFooterShow() {
	return store.state[VuexStateKey.FOOTER_SHOW]
}

export function toggleHamburger(type?: 'open' | 'close') {
	store.commit(VuexMutationKey.toggleHamburger, type)
}

export function resetScroll() {
	const elMain = document.querySelector('.el-main')
	// 重置滚动条
	if (elMain) {
		elMain.scrollTop = 0
	}
}
