import store from '@/store'
import { VuexStateKey, VuexMutationKey } from '@/store/vuexEnum'

export function setExtendBread(breadName: string) {
	store.commit(VuexMutationKey.setExtendBread, breadName)
}

export function getExtendBread() {
	return store.state[VuexStateKey.EXTEND_BREAD]
}
