<template>
	<div class="hamburger" @click="toggleHamburger">
		<img class="icon" :class="{ close: isClose }" src="./img/hamburger.png" />
	</div>
</template>
<script setup lang="ts">
import { useStore } from 'vuex'
import { VuexStateKey, VuexMutationKey } from '@/store/vuexEnum'

const store = useStore()
const isClose = $computed(() => !store.state[VuexStateKey.HAMBURGER_OPENED])
const toggleHamburger = () => {
	store.commit(VuexMutationKey.toggleHamburger)
}
</script>

<style scoped lang="scss">
.hamburger {
	cursor: pointer;
	.icon {
		width: 18px;
		height: 18px;
	}
	.icon.close {
		transform: rotate(180deg);
	}
}
</style>
